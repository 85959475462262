<script setup>
	const { icon, alt } = defineProps({
		icon: {
			type: String,
			required: true,
		},
		alt: {
			type: String,
			required: false,
			default: null,
		},
		border: {
			type: Boolean,
			required: false,
			default: false,
		},
		width: {
			type: [Number, Boolean],
			required: false,
			default: false,
		},
		height: {
			type: [Number, Boolean],
			required: false,
			default: false,
		},
	});
</script>

<template>
	<div class="cac-icon" :class="{ border: 'border' }">
		<MessImage :src="icon" :alt="alt" :width="width" :height="height" :isTransparent="true" />
	</div>
</template>

<style lang="scss">
	.cac-icon {
		width: 80px;
		height: 80px;
		box-sizing: content-box;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: white;
		border-radius: 50%;
		@media (min-width: $mobile) {
			width: 100px;
			height: 100px;
		}
		.mess-image {
			width: 60px;
			height: 60px;
		}
	}
</style>
